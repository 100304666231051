//
// RTL Forms
// --------------------------------------------------


.radio,
.checkbox {

  label {
    padding-right: 20px;
    padding-left: auto;
  }
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-right: -20px;
  margin-left: auto;
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  padding-right: 20px;
  padding-left: 0;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-right: 10px; // space out consecutive inline controls
  margin-left: 0;
}

.has-feedback {

  // Ensure icons don't overlap text
  .form-control {
    padding-left: (@input-height-base * 1.25);
    padding-right: 12px;
  }
}
// Feedback icon (requires .glyphicon classes)
.form-control-feedback {
  left: 0;
  right: auto;
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
//
// Heads up! This is mixin-ed into `.navbar-form` in navbars.less.

.form-inline {

  // Kick in the inline
  @media (min-width: @screen-sm-min) {

      label {
        padding-right: 0;
        padding-left: auto;
      }

    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
      margin-right: 0;
      margin-left: auto;
    }

  }
}


// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.form-horizontal {

  // Reset spacing and right align labels, but scope to media queries so that
  // labels on narrow viewports stack the same as a default form example.
  @media (min-width: @screen-sm-min) {
    .control-label {
      text-align: left;
    }
  }

  // Validation states
  //
  // Reposition the icon because it's now within a grid column and columns have
  // `position: relative;` on them. Also accounts for the grid gutter padding.
  .has-feedback .form-control-feedback {
    left: (@grid-gutter-width / 2);
    right: auto;
  }
}
